import * as React from "react";
import NavBar from "../../components/navbar";
import "../../components/homevideo.css";
//import vote from "../../images/bestweb-24-vote4us.webp"; //  BestWeb - logo
import Footer3CS from "../../components/footerbar";
import { Helmet } from "react-helmet";

const gobalPresence = () => {
  return (
    <div className="page-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Global Presence | Lalan Group Sri Lanka</title>
        <meta name="title" content="Global Presence | Lalan Group Sri Lanka" />
        <meta
          name="description"
          content="Here you can view our local and global operating centres. Globally we are established in the UK, Turkey, Oman, Malaysia and Brazil. "
        />
        <meta name="keywords" content="Global Presence" />
        <link
          rel="canonical"
          href="https://www.lalangroup.com/aboutus/gobal-presence/"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Global Presence - Diversified Conglomerates in Sri Lanka | Lalan Group"
        />
        <meta
          property="og:url"
          content="https://www.lalangroup.com/aboutus/gobal-presence/"
        />
        <meta
          property="og:site_name"
          content="Diversified Conglomerates in Sri Lanka | Lalan Group"
        />
      </Helmet>

      <NavBar />

      <div className="fullpage-video">
        {/* Use different images based on screen size */}
        <img
          className="background-video"
          src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/serverless/lalan-group-global-presence-2.jpg"
          alt="background-video"
        />

        {/* Media query for screens up to 600px */}
        <style>
          {`@media (max-width: 600px) {
            .background-video {
              content: url(${"https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/serverless/lalan-group-global-presence-mobile-version-v1.jpg"});
            }
          }`}
        </style>
      </div>
      <p
        style={{
          zIndex: "10",
          position: "absolute",
          left: "100px",
          right: 0,
          color: "white",
          bottom: "90px",
          display: "flex",
          justifyContent: "left",
          alignItems: "left",
        }}
        className="optimanormal"
      >
        Global Presence
      </p>

      {/* BestWeb - start */}
      {/* <p
        style={{
          zIndex: "10",
          position: "absolute",
          left: 0,
          right: "40px",
          bottom: "50px",
          color: "white",
          display: "flex",
          justifyContent: "right",
          alignItems: "left",
        }}
      >
        <div className="bestweb">
          <a
            href="https://www.vote.bestweb.lk/site/www_lalangroup_lk"
            target="_blank"
          >
            <img className="logopic" src={vote} alt="logo" />
          </a>
        </div>
      </p> */}

      {/* BestWeb - end */}
      <Footer3CS />
    </div>
  );
};

export default gobalPresence;
